(function ($) {
	"use strict";
    var Brw_Frontend = { 
       	init: function () {
       		this.brw_datepicker();
            this.submit_button();
            this.ova_collapsed();
            this.ova_load_name_product();
            this.ova_load_tag_product();
            this.ova_field_search_changed();
            this.ova_get_time_to_book_start();
            this.ova_get_time_to_book_end();
            this.ova_change_pickup_location_transport();
            this.ova_change_pick_up_off_other_location();
            this.ova_startdate_perido_time();
            this.ova_ajax_load_packages();
            this.ova_ajax_show_total();
            this.ova_ajax_show_total_after_load();
            this.ova_search_map();
        },

        ova_change_pickup_location_transport: function() {
            $('.ovabrw-booking').on('change', 'select[name="ovabrw_pickup_loc"].ovabrw-transport ', function() {
                $(this).closest('.wrap-item').find('select[name="ovabrw_pickoff_loc"]').empty();

                var pickup_loc  = $(this).val()
                var item_loc    = $(this).find('option[value="'+pickup_loc+'"]').data('item_loc');

                var html_option_dropoff = '';

                if ( item_loc ) {
                    item_loc.forEach(function(item){
                        if ( item ) {
                            html_option_dropoff += '<option value="'+item+'" >'+item+'</option>';
                        }
                    });
 
                    if ( ! html_option_dropoff ) {
                        html_option_dropoff = '<option value="">Select Location</option>';
                    }
                } else {
                    html_option_dropoff = '<option value="">Select Location</option>';
                }

                $(this).closest('.wrap-item').find('select[name="ovabrw_pickoff_loc"]').append(html_option_dropoff);
            });
        },

        ova_change_pick_up_off_other_location: function() {
            $('.ovabrw-booking').on('change', 'select[name="ovabrw_pickup_loc"] ', function() {
                var value_pickup    = $(this).val();
                var text_pickup     = $(this).find('option:selected').text();

                if ( value_pickup === 'other_location' ) {
                    $(this).closest('.rental_item').find('.ovabrw-other-location').append('<input style="margin-top: 10px;" name="ovabrw_pickup_loc" type="text" placeholder="'+text_pickup+'">');
                } else {
                    $(this).closest('.rental_item').find('.ovabrw-other-location').empty();
                }
            });

            $('.ovabrw-booking').on('change', 'select[name="ovabrw_pickoff_loc"] ', function() {
                var value_pickup    = $(this).val();
                var text_pickup     = $(this).find('option:selected').text();

                if ( value_pickup === 'other_location' ) {
                    $(this).closest('.rental_item').find('.ovabrw-other-location').append('<input style="margin-top: 10px;" name="ovabrw_pickoff_loc" type="text" placeholder="'+text_pickup+'">');
                } else {
                    $(this).closest('.rental_item').find('.ovabrw-other-location').empty();
                }
            });
        },

        ova_get_time_to_book_start: function( time_to_book ) {
            var time_to_book_arr        = [];
            var time_to_book_general    = [];

            if ( typeof brw_time_to_book_general_calendar !== 'undefined' ) {
                time_to_book_general =  brw_time_to_book_general_calendar.replace(/ /g,'').split( ',' );
            }

            if ( typeof time_to_book == 'undefined' ) {
                time_to_book_arr = time_to_book_general;
            } else if ( time_to_book == 'no' ) {
                time_to_book_arr = [];
            } else {
                time_to_book_arr =  time_to_book.replace(/ /g,'').split( ',' );
            }

            return time_to_book_arr;
        },

        ova_get_time_to_book_end: function( time_to_book ) {
            var time_to_book_arr            = []; 
            var time_to_book_endate_general = [];

            if ( typeof brw_time_to_book_general_calendar_endate !== 'undefined' ) {
                time_to_book_endate_general =  brw_time_to_book_general_calendar_endate.replace(/ /g,'').split( ',' );
            }

            if ( typeof time_to_book == 'undefined' ) {
                time_to_book_arr = time_to_book_endate_general;
            } else if ( time_to_book == 'no' ) {
                time_to_book_arr = [];
            } else {
                time_to_book_arr =  time_to_book.replace(/ /g,'').split( ',' );
            }

            return time_to_book_arr;
        },

        ova_field_search_changed: function() {
            /* Attribute Changed */
            $('.ovabrw_search select[name="ovabrw_attribute"]').on('change', function() {
                $(this).closest('.wrap_content').children('.s_field.ovabrw-value-attribute').css('display', 'none');

                var val = $(this).val();

                if ( val ){
                    $(this).closest('.wrap_content').children('.s_field#' + val).css('display', 'inline-block');    
                }
            });

            /* Cat Changed */
            $('.ovabrw_search select[name="cat"]').on('change', function() {
                var form    = $(this).closest('form');
                var cat_val = $(this).val();

                ovas_show_cus_tax( form, cat_val );
            });

            if (  $('.ovabrw_search').length ){
                ovas_show_cus_tax(  $('.ovabrw_search'), '' );
            }
            
            /* Cat when the page loaded*/
            if ( $( '.ovabrw_search select[name="cat"]' ).length ) {
                var form    = $('form.ovabrw_search');
                var cat_val = $( 'form.ovabrw_search select[name="cat"]' ).val();

                if ( cat_val ) {
                    ovas_show_cus_tax( form, cat_val );    
                }
            }
        },

        ova_load_name_product: function() {
            $('.ovabrw_wd_search input[name="ovabrw_name_product"]').autocomplete({
                source: function(request, response) {
                    $.ajax({
                        url: ajax_object.ajax_url,
                        type: 'POST',
                        dataType: "json",
                        data: {
                           action: 'ovabrw_load_name_product',
                           keyword: request.term,
                        },
                        success:function(data) {
                           response(data);
                        },
                    })
                },
                delay: 0,
            });
        },

        ova_load_tag_product: function() {
            $('.ovabrw_wd_search input[name="ovabrw_tag_product"]').autocomplete({
                source: function(request, response) {
                    $.ajax({
                        url: ajax_object.ajax_url,
                        type: 'POST',
                        dataType: "json",
                        data: {
                           action: 'ovabrw_load_tag_product',
                           keyword: request.term,
                        },
                        success:function(data) {

                           response(data);
                        },
                    })
                },
                delay: 0,
            });
        },

        brw_datepicker: function() {
            // get firstday
            var firstday = $('.ovabrw_datetimepicker').data('firstday');

            /* Calendar language */
            if ( typeof brw_lang_general_calendar !== 'undefined' ) {
                $.datetimepicker.setLocale( brw_lang_general_calendar );
            }

            var disweek_arr = '';
            if ( typeof brw_disable_week_day !== 'undefined' ) {
                disweek_arr = brw_disable_week_day.split(',').map( function(item) {
                    return parseInt(item, 10);
                });
            }

            var allowTimes = '';
            if ( typeof brw_time_to_book_general_calendar !== 'undefined' ) {
                allowTimes =  brw_time_to_book_general_calendar.replace(/ /g,'').split( ',' );
            }

            var data_step = 30;
            if ( typeof brw_data_step !== 'undefined' ) {
                data_step = brw_data_step;
            }

            var time_format = '';
            if ( typeof brw_format_time !== 'undefined' ) {
                time_format = brw_format_time;
            }
            
            var date_format = '';
            if ( typeof brw_date_format !== 'undefined' ) {
                date_format = brw_date_format;
            }

            switch ( date_format ) {
                case 'd-m-Y':
                    date_format = 'DD-MM-Y';
                    break;
                case 'm/d/Y':
                    date_format = 'MM/DD/Y';
                    break;
                case 'Y/m/d':
                    date_format = 'Y/MM/DD';
                    break;
                case 'Y-m-d':
                    date_format = 'Y-MM-DD';
                    break;
                default:
                    date_format = 'DD-MM-Y';
            }
            
            var today = new Date();

            // Setup Mask date for datetimepicker via moment library
            $.datetimepicker.setDateFormatter({
                parseDate: function ( date, format ) {
                    var d = moment(date, format);
                    return d.isValid() ? d.toDate() : false;
                },
                
                formatDate: function ( date, format ) {
                    return moment(date).format(format);
                },
            });

            // Check mobile
            if ( $(window).width() <= 1024 ) {
                $('.ovabrw_datetimepicker').datetimepicker({closeOnTimeSelect: false}); // Not closed when selecting time.
            }

            $('.ovabrw_datetimepicker').each( function() {
                /* Disable Date */
                var disabledDates       = [];
                var order_time_arr_new  = [];
                var order_time          = $(this).attr( 'order_time' );

                var product_disable_week_day = $(this).data('disable-week-day');
                if ( product_disable_week_day ) {
                    disweek_arr = product_disable_week_day.toString().split(',').map(function(item) {
                        return parseInt(item, 10);
                    });
                }

                if ( order_time ) {
                    order_time_arr_new = JSON.parse( order_time );
                }
               
                order_time_arr_new.forEach( function(item, index) {
                    if ( item.hasOwnProperty('rendering') ) {
                        if ( item.start_v2 ) {
                            disabledDates.push(item.start_v2);
                        }
                    }
                });

                // Default Hour Start
                var defaultTime = $(this).attr('default_hour');

                var datePickerOptions = {
                    scrollInput: false,
                    dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    format: date_format+' '+time_format,
                    formatDate: date_format,
                    formatTime:time_format,
                    step: data_step, 
                    allowTimes: allowTimes,
                    timepicker:true,
                    autoclose: true,
                    disabledDates: disabledDates,
                    defaultTime: defaultTime,
                }

                if ( $(this).hasClass('no_time_picker') ) {
                    datePickerOptions = {
                        scrollInput: false,
                        dayOfWeekStart: firstday,
                        minDate: today,
                        disabledWeekDays: disweek_arr,
                        format: date_format,
                        formatDate: date_format,
                        formatTime:time_format,
                        step: data_step, 
                        allowTimes: allowTimes,
                        timepicker:true,
                        autoclose: true,
                        disabledDates: disabledDates,
                        defaultTime: defaultTime
                    };
                }

                $(this).datetimepicker(datePickerOptions);
            });

        	$('.ovabrw_datetimepicker.ovabrw_start_date').on('click', function() {
                /* Disable Date */
                var disabledDates       = [];
                var order_time_arr_new  = [];
                var order_time          = $(this).attr( 'order_time' );

                var product_disable_week_day = $(this).data('disable-week-day');
                if ( product_disable_week_day ) {
                    disweek_arr = product_disable_week_day.toString().split(',').map(function(item) {
                        return parseInt(item, 10);
                    });
                }

                if ( order_time ) {
                    order_time_arr_new = JSON.parse( order_time );
                }
               
                order_time_arr_new.forEach( function(item, index) {
                    if ( item.hasOwnProperty('rendering') ) {
                        if ( item.start_v2 ) {
                            disabledDates.push(item.start_v2);
                        }
                    }
                });
                
                // Default Hour Start
                var defaultTime = $(this).attr('default_hour');

                var time_format = '';
                if ( typeof brw_format_time !== 'undefined' ) {
                    time_format = brw_format_time;    
                }

                var timepicker = $(this).attr('timepicker');
                if ( timepicker == 'true' ) {
                    timepicker = true;
                } else {
                    timepicker = false;
                }

                var time_to_book    = $(this).attr('time_to_book');
                var allowTimes      = Brw_Frontend.ova_get_time_to_book_start(time_to_book);

        		var datePickerOptions = {
    				dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    format: date_format+' '+time_format,    
                    formatDate: date_format,
                    formatTime: time_format,
                    defaultTime: defaultTime,
                    allowTimes: allowTimes,
                	timepicker: timepicker,
                    disabledDates: disabledDates,
                };

                if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                    datePickerOptions = {
                        dayOfWeekStart: firstday,
                        minDate: today,
                        disabledWeekDays: disweek_arr,
                        autoclose: true,
                        step: data_step,
                        format: date_format,    
                        formatDate: date_format,
                        defaultTime: false,
                        allowTimes: allowTimes,
                        timepicker: false,
                        disabledDates: disabledDates,
                    };
                }

                $(this).datetimepicker(datePickerOptions);
            });

            $('.ovabrw_datetimepicker.ovabrw_end_date').on('click',function() {
                /* Disable Date */
                var disabledDates       = [];
                var order_time_arr_new  = [];
                var order_time          = $(this).attr( 'order_time' );

                var product_disable_week_day = $(this).data('disable-week-day');
                if ( product_disable_week_day ) {
                    disweek_arr = product_disable_week_day.toString().split(',').map(function(item) {
                        return parseInt(item, 10);
                    });
                }

                if ( order_time ) {
                    order_time_arr_new = JSON.parse( order_time );
                }
               
                order_time_arr_new.forEach(function(item, index) {
                    if ( item.hasOwnProperty('rendering') ) {
                        if ( item.start_v2 ) {
                            disabledDates.push(item.start_v2);
                        }
                    }
                });

                var timepicker = $(this).attr('timepicker');
                if ( timepicker == 'true' ) {
                    timepicker = true;
                } else {
                    timepicker = false;
                }

                var time_format = '';
                if ( typeof brw_format_time !== 'undefined' ) {
                    time_format = brw_format_time;    
                }
                
                var time_to_book    = $(this).attr('time_to_book');
                var allowTimes      = Brw_Frontend.ova_get_time_to_book_end(time_to_book);
                var defaultTime     = $(this).attr('default_hour');

                if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                    timepicker  = false;
                    time_format = '';
                    defaultTime = false;
                } 

                /* Get Min Time */
                var min = $(this).closest('form').find('.ovabrw_start_date').val();
                if ( ! min ) {
                    min = $('.ovabrw_start_date.ovabrw_datetimepicker').val();
                }

                var datePickerOptions = {
                    dayOfWeekStart: firstday,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    minDate: min ? min : false,
                    format: date_format+' '+time_format,
                    formatDate: date_format,
                    formatTime:time_format,
                    defaultTime: defaultTime,
                    allowTimes: allowTimes,
                    timepicker:timepicker,
                    disabledDates: disabledDates,
                }

                if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                    datePickerOptions = {
                        dayOfWeekStart: firstday,
                        disabledWeekDays: disweek_arr,
                        autoclose: true,
                        step: data_step,
                        minDate: min ? min : false,
                        format: date_format,
                        formatDate: date_format,
                        defaultTime: false,
                        allowTimes: allowTimes,
                        timepicker: false,
                        disabledDates: disabledDates,
                    };
                }

                $(this).datetimepicker(datePickerOptions);
            });

            //date picker period (date, no time)
            $('.ovabrw_datetimepicker.date_picker_period').each( function() {
                var hour_default    = $(this).data('hour_default');
                var time_step       = $(this).data('time_step');         

                var datePickerOptions = {
                    dayOfWeekStart: firstday,
                    minDate: today,
                    format: date_format,
                    timepicker: false,
                }

                $(this).datetimepicker(datePickerOptions);
            });

            $('.ovabrw_datetimepicker.no_time_picker').each(function(){
                var datePickerOptions = {
                    timepicker:false,
                }

                $(this).datetimepicker(datePickerOptions);
            });
        },

        submit_button: function() {
        	$('.ovabrw_btn_submit').on('click', function(e) {
    			var content_required = $(this).closest('.ovabrw_search').data('mesg_required');
    			var flag = true;

    			$(this).closest('.ovabrw_search').find('.content .wrap-error').empty();

                var class_require = $(this).closest('.form_ovabrw').find('.required');
                class_require.each(function(){
    				if ( ! $(this).val() ) {
    					flag = false;
    					$(this).parent('.content').children('.wrap-error').append('<p class="error">'+content_required+'</p>');
    				}
    			});

    			if ( ! flag ) {
    				e.preventDefault();
    				return false;
    			}
    		});

            /* Booking Form submit */
            $('.ovabrw_booking_form button.submit').on('click', function(e) {
                var flag = true;
                $(this).closest('.ovabrw_booking_form').find('.error_item').css('display', 'none');

                $(this).closest('.ovabrw_booking_form').find('.required').each( function() {
                    if ( ! $(this).val() ) {
                        flag = false;
                        $(this).closest('.rental_item').find('.error_item').css('display', 'block');

                        if( $(this).parent('.period_package').length > 0 ) {
                            $(this).parent('.period_package').parent('.rental_item').children('.error_item').css('display', 'block');
                        }
                    }

                    var type = $(this).attr('type');

                    if ( type == 'checkbox' ) {
                        var val = $(this).closest('.rental_item').find('input:checked').val();

                        if ( ! val ) {
                            flag = false;
                            $(this).closest('.rental_item').find('.error_item').css('display', 'block');
                        }
                    }
                });

                if ( ! flag ) {
                    e.preventDefault();
                } 
            });

            /* Request Form submit */
            $('.request_booking button.submit').on('click', function(e){
                var flag = true;
                $(this).closest('.request_booking').find('.error_item').css('display', 'none');

                $(this).closest('.request_booking').find('.required').each( function() {
                    if ( ! $(this).val() ) {
                        flag = false;

                        $(this).closest('.rental_item').children('.error_item').css('display', 'block');

                        if ( $(this).parent('.period_package').length > 0 ) {
                            $(this).parent('.period_package').parent('.rental_item').children('.error_item').css('display', 'block');
                        }
                    }

                    var type = $(this).attr('type');

                    if ( type == 'checkbox' ) {
                        var val = $(this).closest('.rental_item').find('input:checked').val();

                        if ( ! val ) {
                            flag = false;
                            $(this).closest('.rental_item').find('.error_item').css('display', 'block');
                        }
                    }
                });

                if ( ! flag ) {
                    e.preventDefault();
                }
            });

            /* File */
            $('.ovabrw-file input[type="file"]').each( function() {
                $(this).on( 'change', function(e) {
                    e.preventDefault();
                    var file            = $(this);
                    var limit           = file.data('max-file-size');
                    var max_size_msg    = file.data('max-file-size-msg');
                    var formats_msg     = file.data('formats');
                    var file_mimes      = file.data('file-mimes');
                    var formats_file    = [];

                    if ( typeof( file_mimes ) == 'object' ) {
                        $.each( file_mimes, function( key, val ) {
                            formats_file.push( val );
                        });
                    }

                    file.closest('.ovabrw-file').find('.ovabrw-file-name').html('')
                    
                    var name = this.files[0].name;
                    var size = this.files[0].size;
                    var type = this.files[0].type;
                    
                    if ( name && size && type ) {
                        file.closest('.ovabrw-file').find('.ovabrw-file-name').html(name);

                        var mb = ( size/1048576 ).toFixed(2);

                        if ( mb > limit ) {
                            file.closest('.ovabrw-file').find('.ovabrw-file-name').html(max_size_msg);
                        }

                        if ( $.inArray( type, formats_file ) == -1 ) {
                            file.closest('.ovabrw-file').find('.ovabrw-file-name').html(formats_msg);
                        }
                    }
                });
            });
        },

        ova_collapsed: function() {
            $('.ovabrw-according').off().on('click', function(e) {
                e.preventDefault();
                $(this).siblings('.ovabrw_collapse_content').slideToggle();
            });

            //open popup
            $('.ovabrw_open_popup').off().on('click', function(e) {
                e.preventDefault();
                $(this).siblings('.popup').css('display', 'block');
            });

            //close popup
            $('.popup-close').on('click', function(e) {
                e.preventDefault();
                $(this).parent('.popup-inner').parent('.popup').css('display', 'none');
            });

            $('.popup-close-2').on('click', function(e) {
                e.preventDefault();
                $(this).parent('.close_discount').parent('.popup-inner').parent('.popup').css('display', 'none');
            });
        },

        ova_startdate_perido_time: function() {
            $('.ovabrw_datetimepicker.startdate_perido_time').focus(function(e) {
                $(this).blur();
            });
            
            /* Choose pickup date in period time */
            $( 'body' ).on( 'change', '.startdate_perido_time', function() {
                var that = $(this);
                if ( that.val() ) {
                    that.closest('form').find( '.show_ajax_content' ).removeClass('active');
                    that.closest('form').find( '.show_total' ).html('');
                    Brw_Frontend.ova_ajax_load_packages(that);
                }
            });
        },

        ova_ajax_load_packages: function( that = null ) {
            if ( that != null ) {
                var startdate = that.val();
                var post_id = that.data('pid');

                that.closest('form').find( 'select[name="ovabrw_period_package_id"]' ).attr( 'disabled', 'disabled' );

                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: ({
                        action: 'ovabrw_get_package_by_time',
                        startdate: startdate,
                        post_id: post_id
                    }),
                    success: function(response) {
                        that.closest('form').find( 'select[name="ovabrw_period_package_id"] option ' ).remove();

                        var option          = '';
                        var option_obj = $.parseJSON( response );

                        if ( option_obj ) {
                            $.each(option_obj, function(key, value) {
                                option += '<option value="'+ key + '">' + value + '</option>';  
                            });
                        }

                        if ( option ) {
                            that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).append(option);
                        }

                        if ( that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).hasClass("select2-hidden-accessible") ) {
                            that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).select2();
                        }

                        that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).prop("disabled", false);
                        that.closest('form').find( '.show_ajax_content' ).removeClass('active');
                        that.closest('form').find( '.show_total' ).html('');
                    },
                });
            }
        },

        ova_ajax_show_total: function(){
            $( 'body' ).on( 'change', '.booking_form', function(){
                var pickup_date = $(this).find('input[name="ovabrw_pickup_date"]').val();

                if ( pickup_date ) {
                    Brw_Frontend.ova_ajax_show_total_after_load($(this));
                }
            });

            $('.booking_form').each( function() {
                var that = $(this);
                var run_ajax = that.data('run_ajax');

                if ( run_ajax != false ) {
                    var check_in    = that.find('input[name="ovabrw_pickup_date"]').val();
                    var check_out   = that.find('input[name="ovabrw_pickoff_date"]').val();
                
                    if ( check_in && check_out ) {
                        Brw_Frontend.ova_ajax_show_total_after_load(that);
                    }
                }
            });
        },

        ova_ajax_show_total_after_load: function( that = null ) {
            var pickup_loc, dropoff_loc, pickup_date, dropoff_date, quantity, resources, services, id, package_id, deposit;
            var resources = [];
            var services = [];
            var obj_resource, obj_service;
            var custom_ckf = {};

            if ( that != null ) {
                var current_form = that;

                current_form.find( '.ajax_loading' ).addClass('active');
                
                if ( current_form.find('input[name="car_id"]').val() ) {
                    id = current_form.find('input[name="car_id"]').val();
                }

                if ( current_form.find('select[name="ovabrw_pickup_loc"]').val() ) {
                    pickup_loc = current_form.find('select[name="ovabrw_pickup_loc"]').val();
                }

                if ( current_form.find('select[name="ovabrw_pickoff_loc"]').val() ) {
                    dropoff_loc = current_form.find('select[name="ovabrw_pickoff_loc"]').val();
                }

                if ( current_form.find('input[name="ovabrw_pickup_date"]').val() ) {
                    pickup_date = current_form.find('input[name="ovabrw_pickup_date"]').val();
                }

                if ( current_form.find('input[name="ovabrw_pickoff_date"]').val() ) {
                    dropoff_date = current_form.find('input[name="ovabrw_pickoff_date"]').val();
                }

                if ( current_form.find('select[name="ovabrw_period_package_id"]').val() ) {
                    package_id = current_form.find('select[name="ovabrw_period_package_id"]').val();
                }
                
                if ( current_form.find('input[name="ovabrw_number_vehicle"]').val() ) {
                    quantity = current_form.find('input[name="ovabrw_number_vehicle"]').val();
                }

                if ( current_form.find('input[name="ova_type_deposit"]:checked').val() ) {
                    deposit = current_form.find('input[name="ova_type_deposit"]:checked').val();
                }

                if ( current_form.find('input[name="data_custom_ckf"]') ) {
                    var ckf = current_form.find('input[name="data_custom_ckf"]').data('ckf');
                    
                    if ( ckf ) {
                        $.each( ckf, function( key, obj ) {
                            if ( obj.type == 'radio' ) {
                                custom_ckf[key] = current_form.find('input[name="'+key+'"]:checked').val();
                            }
                            if ( obj.type == 'checkbox' ) {
                                var ob_checkbox = [];
                                current_form.find(".ovabrw-checkbox input[type=checkbox]:checked").each(function () {
                                    ob_checkbox.push($(this).val());
                                });

                                custom_ckf[key] = ob_checkbox;
                            }
                            if ( obj.type == 'select' ) {
                                custom_ckf[key] = current_form.find('select[name="'+key+'"]').val();
                            }
                        });
                    }
                }

                // Reference the CheckBoxes and insert the checked CheckBox value in Array.
                current_form.find(".ovabrw_resource input[type=checkbox]:checked").each(function () {
                    resources[$(this).data('resource_key')] = $(this).val();
                });
                
                // Convert to object
                obj_resource = $.extend({}, resources ) ;

                // Service
                current_form.find( "select[name='ovabrw_service[]']" ).each( function() {
                    if ( $(this).val() ) {
                        services.push($(this).val());    
                    }
                });
              
                obj_service = $.extend({}, services ) ;
            
                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: ({
                        action: 'ovabrw_calculate_total',
                        pickup_loc: pickup_loc,
                        dropoff_loc: dropoff_loc,
                        pickup_date: pickup_date,
                        package_id: package_id,
                        dropoff_date: dropoff_date,
                        quantity: quantity,
                        deposit: deposit,
                        resources: JSON.stringify( obj_resource ),
                        services: JSON.stringify( obj_service ),
                        custom_ckf: JSON.stringify( custom_ckf ),
                        id: id,
                    }),
                    success: function(response){
                        var data = JSON.parse(response);

                        if ( response != 0 ) {
                            that.find( '.show_ajax_content' ).addClass('active');
                            that.find( '.show_total' ).html('').append( data['line_total'] );
                            if ( data['error'] ) {
                                that.find( '.show_ajax_content' ).removeClass('active');
                                that.find( '.show_total' ).html('');
                                that.find( '.ajax-show-error' ).html('').append( data['error'] ).show();
                            } else if ( data['number_vehicle_available'] > 0 ) {
                                that.find( '.show_availables_vehicle' ).html('').append( data['number_vehicle_available'] );
                                that.find( 'input[name="ovabrw_number_vehicle"]' ).attr('max', data['number_vehicle_available']);
                                that.find( '.ajax-show-error' ).html('').hide();
                            } else {
                                that.find( '.show_availables_vehicle' ).html('').append( 0 );
                                that.find( 'input[name="ovabrw_number_vehicle"]' ).attr('max', 1);
                                that.find( '.ajax-show-error' ).html('').hide();
                            }
                        } else {
                            that.find( '.show_ajax_content' ).removeClass('active');
                            that.find( '.show_total' ).html('');
                            that.find( '.ajax-show-error' ).html('').hide();
                        }

                        current_form.find( '.ajax_loading' ).removeClass('active');
                    },
                });
            }
        },

        ova_search_map: function() {
            var zoomMap         = parseInt($(document).find('.wrap_search_map .wrap_search .search_result').attr('data-zoom'));
            var default_loc     = $(document).find('.wrap_search_map .wrap_search .search_result').attr('data-default-location');
            var latDefault      = parseFloat($(document).find('.wrap_search_map .wrap_search .search_result').attr('data-lat'));
            var lngDefault      = parseFloat($(document).find('.wrap_search_map .wrap_search .search_result').attr('data-lng'));
            var marker_option   = $(document).find('.wrap_search_map .wrap_search .search_result').attr('data-marker_option');
            var marker_icon     = $(document).find('.wrap_search_map .wrap_search .search_result').attr('data-marker_icon');

            /* Select2 */
            var select = $(document).find('.wrap_search_map select');
            if ( select.length > 0 ) {
                select.select2();
            }

            /* averageGeolocation */
            function averageGeolocation(data) {
                if ( data.length == 1 ) {
                   return data[0];
                }

                let $numCoords = data.length;

                let $X = 0.0;
                let $Y = 0.0;
                let $Z = 0.0;

                for ( let i = 0; i < data.length; i++ ) {

                   var lat = data[i]['lat'] * Math.PI / 180;
                   var lng = data[i]['lng'] * Math.PI / 180;

                   let $a = Math.cos(lat) * Math.cos(lng);
                   let $b = Math.cos(lat) * Math.sin(lng);
                   let $c = Math.sin(lat);

                   $X += $a;
                   $Y += $b;
                   $Z += $c;
                }

                $X = $X / $numCoords;
                $Y = $Y / $numCoords;
                $Z = $Z / $numCoords;

                lng = Math.atan2($Y, $X);
                let $hyp = Math.sqrt($X * $X + $Y * $Y);
                lat = Math.atan2($Z, $hyp);

                return {
                   'lat': lat * 180 / Math.PI,
                   'lng': lng * 180 / Math.PI
                };
            }

            /* markerClusterer */
            function markerClusterer() {
                var locations           = [];
                var locationsAverage    = [];

                $(document).find('.data_product').each( function() {
                    locations.push(JSON.parse(JSON.stringify({
                        'title': $(this).attr('data-title_product'),
                        'link': $(this).attr('data-link_product'),
                        'price': $(this).attr('data-html_price'),
                        'average_rating': $(this).attr('data-average_rating'),
                        'number_comment': $(this).attr('data-number_comment'),
                        'thumbnail': $(this).attr('data-thumbnail_product'),
                        'lat': $(this).attr('data-map_lat_product'),
                        'lng': $(this).attr('data-map_lng_product'),
                    })));

                    locationsAverage.push(JSON.parse(JSON.stringify({
                      'lat': parseFloat($(this).attr('data-map_lat_product')),
                      'lng': parseFloat($(this).attr('data-map_lng_product')),
                    })));
                });

                if ( default_loc === 'yes' ) {
                    var map = new google.maps.Map(document.getElementById('show_map'), {
                        center: {
                            lat: parseFloat(latDefault),
                            lng: parseFloat(lngDefault)
                        },
                        zoom: zoomMap,
                        gestureHandling: 'cooperative'
                    });
                } else {
                    locationsAverage = averageGeolocation(locationsAverage);
                    if (isNaN(locationsAverage.lat) || isNaN(locationsAverage.lng)) {
                        var map = new google.maps.Map(document.getElementById('show_map'), {
                            center: {
                                lat: parseFloat(latDefault),
                                lng: parseFloat(lngDefault)
                            },
                            zoom: zoomMap,
                            gestureHandling: 'cooperative'
                        });
                    } else {
                        var map = new google.maps.Map(document.getElementById('show_map'), {
                            center: {
                                lat: parseFloat(locationsAverage.lat),
                                lng: parseFloat(locationsAverage.lng)
                            },
                            zoom: zoomMap,
                            gestureHandling: 'cooperative'
                        });
                    }
                }

                var infowindow = new google.maps.InfoWindow();

                var oms = new OverlappingMarkerSpiderfier(map, { 
                    markersWontMove: true,   // we promise not to move any markers, allowing optimizations
                    markersWontHide: true,   // we promise not to change visibility of any markers, allowing optimizations
                    basicFormatEvents: true  // allow the library to skip calculating advanced formatting information
                });

                var markers = locations.map(function(location, i) {
                    var lat = parseFloat(location.lat);
                    var lng = parseFloat(location.lng);

                    var average_rating = location.average_rating;
                    var floor_num_rating = Math.floor(average_rating);
                    var empty_num_rating = 5 - Math.ceil(average_rating);
                    var number_comment = location.number_comment;
                    var star = '';

                    if ( floor_num_rating > 0 ) {
                        for (var $i = 1; $i <= floor_num_rating; $i++) {
                            star += '<i class="fa fa-star"></i>';
                        }
                    }

                    if ( floor_num_rating != Math.ceil(average_rating) ) {
                        star += '<i class="fa fa-star-half-alt"></i>';
                    }

                    if ( empty_num_rating > 0 ) {
                        for (var $j = 1; $j <= empty_num_rating; $j++) {
                            star += '<i class="fa fa-star-o"></i>';
                        }
                    }

                    if ( ! average_rating || average_rating == 0 ) {
                        var star = '';
                    }    else {}

                    var show_comment = '';

                    if ( number_comment != 0 ) {
                        show_comment += '<span class="number second_font">(' + location.number_comment + ')</span>';
                    }

                    var contentString = '<div class="data_product_map">' +
                        '<a href="' + location.link + '">' +
                        '<img style="max-width: 100%; width: 280px;" src="' + location.thumbnail + '" >' +
                        '<h2 class="title"><a href="' + location.link + '">' + location.title + '</a></h2>' + location.price + 
                        '<div class="product_ratting"><span class="star">' + star + '</span> ' + show_comment + ' </div>' +
                        '</a>' + 
                        '</div>';

                    var latlngset = new google.maps.LatLng(lat, lng);

                    if (marker_option == 'icon') {
                        var marker = new google.maps.Marker({
                            position: latlngset,
                            icon: marker_icon,
                        });
                    }
                
                    google.maps.event.addListener(marker, 'spider_click', function(e) {  // 'spider_click', not plain 'click'
                    infowindow.setContent(contentString);
                    infowindow.open(map, marker);
                    });

                    oms.addMarker(marker);
                   
                });

                window.map = map;  // for debugging/exploratory use in console
                window.oms = oms;  // ditto
            }

            /* load ajax search map */
            function loadAjaxSearchMap() {
                var that        = $(document).find('.wrap_search_map');
                var name        = that.find('[name="ovabrw_name_product"]').val();
                var cat         = that.find('[name="cat"]').val();
                var sort        = that.find('[name="sort"]').val();
                var radius      = that.find('[name="radius"]').val();
                var map_lat     = that.find('[name="map_lat"]').val();
                var map_lng     = that.find('[name="map_lng"]').val();
                var pickup_loc  = that.find('[name="ovabrw_pickup_loc"]').val();
                var dropoff_loc = that.find('[name="ovabrw_dropoff_loc"]').val();
                var start_date  = that.find('[name="ovabrw_pickup_date"]').val();
                var end_date    = that.find('[name="ovabrw_pickoff_date"]').val();
                var column      = that.find('.search_result').attr('data-column');
                var order       = that.find('.search_result').attr('data-order');
                var orderby     = that.find('.search_result').attr('data-orderby');
                var per_page    = that.find('.search_result').attr('data-per_page');
                var paged       = that.find('.ovabrw_pagination_ajax .current').attr('data-paged');
                var attribute   = that.find('[name="ovabrw_attribute"]').val();
                var attr_value  = that.find('[name="ovabrw_attribute_value"]').val();
                var tags        = that.find('[name="ovabrw_tag_product"]').val();
                var result      = that.find('.search_result');

                $(document).find('.wrap_search_map .wrap_load_more').show();
                $(document).find('.wrap_search_map .ovabrw_product_archive').hide();

                var data_ajax   = {
                    action: 'ovabrw_search_map',
                    name: name,
                    cat: cat,
                    radius: radius,
                    map_lat: map_lat,
                    map_lng: map_lng,
                    pickup_loc: pickup_loc,
                    dropoff_loc: dropoff_loc,
                    start_date: start_date,
                    end_date: end_date,
                    sort: sort,
                    column: column,
                    order: order,
                    orderby: orderby,
                    per_page: per_page,
                    paged: paged,
                    attribute: attribute,
                    attr_value: attr_value,
                    tags: tags,
                };

                var taxonomies  = that.find('[name="data_taxonomy_custom"]').val();
                var taxo_custom = '';

                if ( taxonomies ) {
                    data_ajax['taxonomies'] = taxonomies;
                    taxo_custom = JSON.parse( taxonomies );
                    $.each( taxo_custom, function( key, value ) {
                        data_ajax[key] = that.find('[name="'+ key +'_name"]').val();
                    });
                }

                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: data_ajax,
                    success:function(response) {
                        if ( response ) {
                            var json = JSON.parse( response );
                            var item = $(json.result).fadeOut(300).fadeIn(500);

                            result.html(item);
                            $(document).find('.wrap_search_filter_title .results_found').html( json.results_found );

                            $(document).find('.wrap_search_map .wrap_load_more').hide();

                            if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                                markerClusterer();
                            }
                        }
                    },
                });
            }

            /* show, hide taxonomy */
            if ( $('.wrap_search_map .show_taxonomy').length > 0 ) {
                var show_taxonomy = $('.wrap_search_map .show_taxonomy').attr('data-show_taxonomy');
                if ( show_taxonomy == 'yes' ) {
                    $('.wrap_search_map .wrap_search_taxonomies').hide();
                } else {
                    $('.wrap_search_map .wrap_search_taxonomies').show();
                }
            }

            $.fn.event_map = function(paramObject) {
                paramObject = $.extend({
                   lat: latDefault,
                   lng: lngDefault,
                   zoom: zoomMap
                }, paramObject);

                var input = document.getElementById('pac-input');

                var autocomplete = '';
                if( input ){
                   var autocomplete = new google.maps.places.Autocomplete(input);
                }

                var locations = [];

                $('.data_product').each(function() {
                    locations.push(JSON.parse(JSON.stringify({
                        'title': $(this).attr('data-title_product'),
                        'thumbnail': $(this).attr('data-thumbnail_product'),
                        'average_rating': $(this).attr('data-average_rating'),
                        'number_comment': $(this).attr('data-number_comment'),
                        'lat': $(this).attr('data-map_lat_product'),
                        'lng': $(this).attr('data-map_lng_product'),
                        'link': $(this).attr('data-link_product'),
                    })));
                });

                /* Add a marker clusterer to manage the markers. */
                if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                   markerClusterer();
                }

                if( autocomplete !== '' ){
                    
                    autocomplete.addListener('place_changed', function() {

                        var place = autocomplete.getPlace();
                        if (!place.geometry) {
                            return;
                        }

                        var map = new google.maps.Map(document.getElementById('show_map'), {
                            center: {
                                lat: parseFloat(place.geometry.location.lat()),
                                lng: parseFloat(place.geometry.location.lng())
                            },
                            zoom: zoomMap,
                            gestureHandling: 'cooperative'
                        });

                        $("#map_name").val(place.name);
                        $("#map_address").val(place.formatted_address);

                        $('#map_lat').val(place.geometry.location.lat());
                        $('#map_lng').val(place.geometry.location.lng());

                        var point = {};
                        point.lat = place.geometry.location.lat();
                        point.lng = place.geometry.location.lng();
                        updatePointSearch(point);
                    });
                }
            }

            if ( typeof google !== 'undefined' && $("#show_map").length > 0 ) {
                var map_lat = parseFloat($('input#map_lat').val() ? $('input#map_lat').val() : latDefault);
                var map_lng = parseFloat($('input#map_lng').val() ? $('input#map_lng').val() : lngDefault);

                var $w_h = $(window).height();
                $("#show_map").css('height', $w_h );

                if ( navigator.geolocation ) {
                   navigator.geolocation.getCurrentPosition(showPosition);
                }

                $("#show_map").event_map({
                   lat: map_lat,
                   lng: map_lng,
                   zoom: zoomMap
                });   
            }

            /* Autocomplete Address */
            function updatePointSearch(point) {
                $(document).find('.wrap_search_map .wrap_load_more').show();
                $(document).find('.wrap_search_map .ovabrw_product_archive').hide();

                loadAjaxSearchMap();
            }

            // Name Change
            $('.wrap_search_map [name="ovabrw_name_product"]').on('change', function(event) {
                loadAjaxSearchMap();
            });

            // Category Change
            $('.wrap_search_map [name="cat"]').on('change', function(event) {
                var show_taxonomy = $('.wrap_search_map .show_taxonomy').attr('data-show_taxonomy');

                if ( show_taxonomy == 'yes' ) {
                    $('.wrap_search_map .wrap_search_taxonomies').hide();
                } else {
                    $('.wrap_search_map .wrap_search_taxonomies').show();
                }

                var form    = $('.wrap_search_map');
                var cat_val = $(this).val();
                ovas_show_cus_tax( form, cat_val );
                loadAjaxSearchMap();
            });

            // Location Change
            $('.wrap_search_map [name="ovabrw_pickup_loc"], .wrap_search_map [name="ovabrw_dropoff_loc"]').on('change', function(event) {
                loadAjaxSearchMap();
            });

            // Date Change
            $('.wrap_search_map [name="ovabrw_pickup_date"], .wrap_search_map [name="ovabrw_pickoff_date"]').on('change', function(event) {
                loadAjaxSearchMap();
            });

            // Attribute Change
            $('.wrap_search_map [name="ovabrw_attribute"], .wrap_search_map [name="ovabrw_attribute_value"]').on('change', function(event) {
                loadAjaxSearchMap();
            });

            // Tags Change
            $('.wrap_search_map [name="ovabrw_tag_product"]').on('change', function(event) {
                loadAjaxSearchMap();
            });

            // Taxonomy Change
            var data_taxonomy_custom = $('.wrap_search_map [name="data_taxonomy_custom"]').val();
            if ( data_taxonomy_custom ) {
                var taxonomies_custom = JSON.parse( data_taxonomy_custom );
                $.each( taxonomies_custom, function( key, value ) {
                    $('.wrap_search_map [name="'+ key +'_name"]').on('change', function(event) {
                        loadAjaxSearchMap();
                    });
                });
            }

            // Filter Change
            $('.wrap_search_map [name="sort"]').on('change', function(envent) {
                loadAjaxSearchMap();
            });


            /* Slider Radius */
            var min_radius      = 0;
            var max_radius      = 0;
            var value_radius    = 0;
            var map_range_radius     = parseInt($(document).find('.wrap_search_map .wrap_search_radius').attr('data-map_range_radius'));
            var map_range_radius_min = parseInt($(document).find('.wrap_search_map .wrap_search_radius').attr('data-map_range_radius_min'));
            var map_range_radius_max = parseInt($(document).find('.wrap_search_map .wrap_search_radius').attr('data-map_range_radius_max'));

            if( typeof map_range_radius_min !== 'undefined' ){
                min_radius = map_range_radius_min;
            }
            if( typeof map_range_radius_max  !== 'undefined' ){
                max_radius = map_range_radius_max;
            }
            if( typeof map_range_radius  !== 'undefined' ){
                value_radius = map_range_radius;
            }

            if ( $('#wrap_pointer').length > 0 ) {
                $('#wrap_pointer').slider({
                    min: min_radius,
                    max: max_radius,
                    step: 1,
                    value: value_radius,
                    change: function(event, ui) {
                        $(this).parents('.wrap_search_map').find('[name="radius"]').val(ui.value);
                        $(this).parents('.wrap_search_map').find('.result_radius').html(ui.value + 'km');

                        loadAjaxSearchMap();
                    }
                });
            }

            /* Pagination */
            $(document).on('click', '.wrap_search_map .ovabrw_pagination_ajax .page-numbers', function(e) {
                e.preventDefault();
                var that    = $(this);
                var current = $('.wrap_search_map .ovabrw_pagination_ajax .current').attr('data-paged');
                var paged   = that.attr('data-paged');
                if ( current != paged ) {
                    $(window).scrollTop(0);
                    $('.wrap_search_map .ovabrw_pagination_ajax .page-numbers').removeClass('current');
                    that.addClass('current');
                    loadAjaxSearchMap();
                }
            });

            /* Click locate me */
            $(document).on('click', '.wrap_search_map .locate_me', function() {
                if (navigator.geolocation) {
                   navigator.geolocation.getCurrentPosition(showPosition);
                } else {
                   x.innerHTML = "Geolocation is not supported by this browser.";
                }
            });

            /* Toggle filters mobile */
            $('.wrap_search_map .toggle_filters').on( 'click' ,function() {
                $(this).parents('.wrap_search_map').find('.form_search_map').slideToggle(300);
                $(this).find('.icon_down').toggle(300);
                $(this).find('.icon_up').toggle(300);
                $(this).toggleClass('active');
            });

            /* Change tabs mobile */
            $('.elementor_search_map .toggle_wrap span').on('click', function() {
                let id = $(this).attr('data-value');

                $('.wrap_search_map .wrap_search, .wrap_search_map .wrap_map').hide();
                $('.' + id).show();

                $('.elementor_search_map .toggle_wrap span').removeClass('active');
                $(this).addClass('active');
            });

            /* Position locate me */
            function showPosition(position) {
                var map_lat = position.coords.latitude;
                var map_lng = position.coords.longitude;

                $('[name="map_lat"]').attr('value', map_lat);
                $('[name="map_lng"]').attr('value', map_lng);

                var latlng = {
                   lat: parseFloat(map_lat),
                   lng: parseFloat(map_lng)
                };
                var geocoder = new google.maps.Geocoder;
                geocoder.geocode({
                   'location': latlng
                }, function( results, status ) {
                   if (status === 'OK') {
                      if (results[0]) {
                         $('.wrap_search_map [name="map_address"]').val(results[0].formatted_address);
                      } else {
                         window.alert('No results found');
                      }
                   } else {
                      window.alert('Geocoder failed due to: ' + status);
                   }
                });

                loadAjaxSearchMap();
            }

            /* Calculator height google map */
            function heightGoogleMap() {
                if ( $(window).width() < 1024 ) {
                    $(document).find('.wrap_search_map #show_map').css({
                       'height': '100%',
                       'width': '100%',
                       'min-height': '600px'
                    });
                } else {
                    $(document).find('.wrap_search_map #show_map').css({
                       'height': $(window).height(),
                       'width': '100%',
                    });
                }
            }
            heightGoogleMap();
            $(window).resize(function() {
                var width = $(window).width();

                if ( width >= 1024 ) {
                    $(document).find('.wrap_search_map .wrap_search').show();
                    $(document).find('.wrap_search_map .wrap_map').show();
                }
                heightGoogleMap();
            });
        },
    };

    /* ready */
    $(document).ready(function () {
        Brw_Frontend.init();
    });

    /* Show Custom Taxonomy in Search Form */
    function ovas_show_cus_tax( form, cat_val ) {
        if ( typeof ova_brw_search_show_tax_depend_cat != 'undefined' && form.length ) {
            if ( ova_brw_search_show_tax_depend_cat == 'yes' ) {
                /* Hide all custom taxonomy select */
                form.find('.s_field_cus_tax').hide();
            } else {
                form.find('.s_field_cus_tax').show();
            }
        }
        
        if ( cat_val ) {
            $.ajax({
                url: ajax_object.ajax_url,
                type: 'POST',
                data: ({
                   action: 'ovabrw_get_tax_in_cat',
                   cat_val: cat_val,
                }),
                success:function(response) {
                    if ( response ) {
                        var list_tax_values = response.split(",");

                        if ( list_tax_values.length ) {
                            for ( var i = 0; i < list_tax_values.length; i++ ) {
                                if ( form.find("."+list_tax_values[i]).length > 0 ) {
                                    form.find("."+list_tax_values[i]).show();
                                }
                            }
                        }
                    }
                },
            });
        }
    }

    document.addEventListener('DOMContentLoaded', function() {
        var date_format = '';

        if ( typeof brw_date_format !== 'undefined' ) {
            date_format = brw_date_format;
        }
        
        $('.wrap_calendar').each( function(e) {
            var id              = $(this).data('id');
            var srcCalendarEl   = $(this).find('.ovabrw__product_calendar')[0];
            if ( srcCalendarEl === null ) return;

            var nav             = srcCalendarEl.getAttribute('data-nav');
            var default_view    = srcCalendarEl.getAttribute('data-default_view');
            var first_day       = srcCalendarEl.getAttribute('data-first-day');

            if ( ! first_day ) {
                first_day = 0;
            }

            if ( default_view == 'month' ) {
                default_view = 'dayGridMonth';
            }
            
            var cal_lang            = srcCalendarEl.getAttribute( 'data-lang' ).replace(/\s/g, '');
            var define_day          = srcCalendarEl.getAttribute('data-define_day');
            var data_event_number   = parseInt( srcCalendarEl.getAttribute('data_event_number') );
            var default_hour_start  = srcCalendarEl.getAttribute( 'default_hour_start' );
            var time_to_book_start  = srcCalendarEl.getAttribute('time_to_book_start');
            time_to_book_start      = Brw_Frontend.ova_get_time_to_book_start(time_to_book_start);
            var price_calendar      = srcCalendarEl.getAttribute('price_calendar');
            price_calendar          = price_calendar.replace(/[\u0000-\u001F]+/g,""); 
            var price_full_calendar_value = JSON.parse( price_calendar );
            var special_time        = srcCalendarEl.getAttribute('data-special-time');
            var special_time_value  = JSON.parse( special_time );
            var background_day      = srcCalendarEl.getAttribute('data-background-day');
            var disable_week_day    = srcCalendarEl.getAttribute('data-disable_week_day');
            var disable_week_day_value = '';

            if ( disable_week_day ) {
                disable_week_day_value = JSON.parse( disable_week_day );
            }

            var events          = '';
            var date_rent_full  = [];
            var order_time      = srcCalendarEl.getAttribute('order_time');

            if ( order_time && order_time.length > 0 ) {
                events = JSON.parse( order_time );
            }

            if ( typeof events !== 'undefined' && events.length > 0 ) {
                events.forEach(function(item, index) {
                    if ( item.hasOwnProperty('rendering') ) {
                        date_rent_full.push( item.start );
                    }
                });
            }
            
            var srcCalendar = new FullCalendar.Calendar(srcCalendarEl, {
                editable: false,
                events: events,
                firstDay: first_day,
                height: 'auto',
                headerToolbar: {
                    left: 'prev,next,today,' + nav,
                    right: 'title',
                },
                initialView: default_view,
                locale: cal_lang,
                dayMaxEventRows: true, // for all non-TimeGrid views
                views: {
                    dayGrid: {
                        dayMaxEventRows: data_event_number
                        // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
                    },
                    timeGrid: {
                        dayMaxEventRows: data_event_number
                        // options apply to timeGridWeek and timeGridDay views
                    },
                    week: {
                        dayMaxEventRows: data_event_number
                        // options apply to dayGridWeek and timeGridWeek views
                    },
                    day: {
                        dayMaxEventRows: data_event_number
                        // options apply to dayGridDay and timeGridDay views
                    }
                },
                dayCellDidMount: function(e) {
                    var new_date    = new Date( e.date );
                    var time_stamp  = Date.UTC( new_date.getFullYear(), new_date.getMonth(), new_date.getDate() )/1000;
                    
                    if ( price_full_calendar_value != '' ) {
                        var type_price = price_full_calendar_value[0].type_price;

                        if ( type_price == 'day' ) {
                            var ovabrw_daily_monday     = price_full_calendar_value[1].ovabrw_daily_monday;
                            var ovabrw_daily_tuesday    = price_full_calendar_value[1].ovabrw_daily_tuesday;
                            var ovabrw_daily_wednesday  = price_full_calendar_value[1].ovabrw_daily_wednesday;
                            var ovabrw_daily_thursday   = price_full_calendar_value[1].ovabrw_daily_thursday;
                            var ovabrw_daily_friday     = price_full_calendar_value[1].ovabrw_daily_friday;
                            var ovabrw_daily_saturday   = price_full_calendar_value[1].ovabrw_daily_saturday;
                            var ovabrw_daily_sunday     = price_full_calendar_value[1].ovabrw_daily_sunday;

                            switch ( new_date.getDay() ) {
                                case 0: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }
                                    
                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ){
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_sunday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_sunday;
                                        }
                                        
                                        return e;    
                                    }
                                    
                                    break;
                                }
                                case 1: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_monday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_monday;
                                        }

                                        return e;
                                    }
                                    
                                    break;
                                }
                                case 2: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_tuesday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_tuesday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 3: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_wednesday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_wednesday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 4: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_thursday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_thursday;
                                        }

                                        return e;
                                    }
                                    break;
                                }
                                case 5: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_friday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;;
                                                    return e;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_friday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 6: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_saturday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_saturday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                            }
                        } else if ( type_price == 'hour' ) {
                            // check disable week day in settings
                            if ( disable_week_day_value ) {
                                $.each( disable_week_day_value, function( key, day_value ) {
                                    if( day_value == new_date.getDay() ) {
                                        e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                        // set background day
                                        $('.unavailable_date').css('background-color', background_day);
                                    }
                                });
                            }

                            var ovabrw_price_hour = price_full_calendar_value[1].ovabrw_price_hour;
                            let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                            if ( el ) {
                                if ( special_time_value ) {
                                    el.innerHTML = ovabrw_price_hour;
                                    $.each( special_time_value, function( price, special_timestamp ) {
                                        if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                            el.innerHTML = price;
                                        }
                                    });
                                } else {
                                    el.innerHTML = ovabrw_price_hour;
                                }
                                
                                return e;
                            }
                        } else if ( type_price == 'mixed' ) {
                            var ovabrw_daily_monday     = price_full_calendar_value[1].ovabrw_daily_monday;
                            var ovabrw_daily_tuesday    = price_full_calendar_value[1].ovabrw_daily_tuesday;
                            var ovabrw_daily_wednesday  = price_full_calendar_value[1].ovabrw_daily_wednesday;
                            var ovabrw_daily_thursday   = price_full_calendar_value[1].ovabrw_daily_thursday;
                            var ovabrw_daily_friday     = price_full_calendar_value[1].ovabrw_daily_friday;
                            var ovabrw_daily_saturday   = price_full_calendar_value[1].ovabrw_daily_saturday;
                            var ovabrw_daily_sunday     = price_full_calendar_value[1].ovabrw_daily_sunday;

                            switch( new_date.getDay() ) {
                                case 0: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }
                                    
                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_sunday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_sunday;
                                        }
                                        
                                        return e;    
                                    }
                                    break;
                                }
                                case 1: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_monday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_monday;
                                        }

                                        return e;
                                    }
                                    break;
                                }
                                case 2: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_tuesday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_tuesday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 3: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_wednesday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_wednesday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 4: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_thursday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_thursday;
                                        }

                                        return e;
                                    }
                                    break;
                                }
                                case 5: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_friday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;;
                                                    return e;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_friday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                                case 6: {
                                    // check disable week day in settings
                                    if ( disable_week_day_value ) {
                                        $.each( disable_week_day_value, function( key, day_value ) {
                                            if( day_value == new_date.getDay() ) {
                                                e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                                // set background day
                                                $('.unavailable_date').css('background-color', background_day);
                                            }
                                        });
                                    }

                                    let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];

                                    if ( el ) {
                                        if ( special_time_value ) {
                                            el.innerHTML = ovabrw_daily_saturday;
                                            $.each( special_time_value, function( price, special_timestamp ) {
                                                if ( time_stamp >= special_timestamp[0] && time_stamp <= special_timestamp[1] ) {
                                                    el.innerHTML = price;
                                                }
                                            });
                                        } else {
                                            el.innerHTML = ovabrw_daily_saturday;
                                        }
                                        
                                        return e;
                                    }
                                    break;
                                }
                            }
                        }
                    } else {
                        var type_price = srcCalendarEl.getAttribute('type_price');
                        // period_time
                        if ( type_price == 'period_time' || type_price == 'transportation' ) {

                            // check disable week day in settings
                            if ( disable_week_day_value ) {
                                $.each( disable_week_day_value, function( key, day_value ) {
                                    if( day_value == new_date.getDay() ) {
                                        e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                        // set background day
                                        $('.unavailable_date').css('background-color', background_day);
                                    }
                                });
                            }

                            return e;
                        }
                    }
                },
                dateClick: function( info ) {
                    var new_date_a = new Date( info.date );            

                    var year  = new_date_a.getFullYear();
                    var month = new_date_a.getMonth() + 1;
                    var day   = new_date_a.getDate();

                    month   = ( month < 10 ) ? '0' + month : month;
                    day     = ( day < 10 ) ? '0' + day : day;

                    var today = new Date();
                    var date_click_24 = new Date( year, month - 1, day, 24, 0 );

                    var date_check_rent_full = year + '-' + month + '-' + day;

                    if ( define_day == "hotel" || $('.rental_item input[name="ovabrw_pickup_date"]').hasClass('no_time_picker') || ( time_to_book_start.length < 1 ) ) {
                        default_hour_start = '';
                    }

                    // check disable week day in settings
                    if ( disable_week_day_value ) {
                        $.each( disable_week_day_value, function( key, day_value ) {
                            if ( day_value == new_date_a.getDay() ) {
                                alert(notifi_disable_day);
                                date_click_24 = 0;
                            }
                        });
                    }

                    if ( ( ! date_rent_full.includes( date_check_rent_full ) ) && ( date_click_24 >= today ) ) {
                        var date_input = date_format;
                        date_input = date_input.replace('Y', year);
                        date_input = date_input.replace('m', month);
                        date_input = date_input.replace('d', day);

                        if ( default_hour_start ) {
                            $('input[name="ovabrw_pickup_date"]').val(date_input + ' ' + default_hour_start);
                        } else {
                            $('input[name="ovabrw_pickup_date"]').val(date_input);
                        }
                        
                        if( document.getElementById("ovabrw_booking_form") ){
                            document.getElementById("ovabrw_booking_form").scrollIntoView({behavior: 'smooth'});
                            $('.startdate_perido_time').each( function() {
                                var that = $(this);
                                if ( that.val() ) {
                                    Brw_Frontend.ova_ajax_load_packages(that);
                                }
                            });
                        }
                    }
                }
            });

            srcCalendar.render();
        }); 
    }); 

})(jQuery);